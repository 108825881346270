<template>
  <div ref="container" class="keen-slider max-w-2xl mx-auto loading group select-none">
    <template v-for="item in items" :key="item.src">
      <div class="keen-slider__slide shrink-0 max-w-fit group-[.loading]:mr-10">
        <NuxtLink :to="`/marken/${item.slug}`">
          <NuxtImg
            :width="item.width"
            :height="item.height"
            loading="lazy"
            :src="item.src"
            :alt="item.alt"
          />
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup>
const items = [
  { slug: "abarth", src: "/partner/abarth.jpg", alt: "Abarth Logo", width: 80, height: 80 },
  {
    slug: "alfaromeo",
    src: "/partner/alfa-romeo.jpg",
    alt: "Alfa Romeo Logo",
    width: 80,
    height: 80,
  },
  { slug: "citroen", src: "/partner/citroen.jpg", alt: "Citroen Logo", width: 80, height: 80 },
  {
    slug: "dsautomobiles",
    src: "/partner/ds-automobiles.jpg",
    alt: "DS-Automobiles Logo",
    width: 80,
    height: 80,
  },
  { slug: "fiat", src: "/partner/fiat.jpg", alt: "Fiat Logo", width: 80, height: 80 },
  { slug: "hyundai", src: "/partner/hyundai.jpg", alt: "Hyundai Logo", width: 80, height: 80 },
  { slug: "jeep", src: "/partner/jeep.jpg", alt: "Jeep Logo", width: 80, height: 80 },
  { slug: "kia", src: "/partner/kia.jpg", alt: "Kia Logo", width: 80, height: 80 },
  { slug: "lancia", src: "/partner/lancia.jpg", alt: "Lancia Logo", width: 120, height: 80 },
  { slug: "opel", src: "/partner/opel.jpg", alt: "Opel Logo", width: 80, height: 80 },
  { slug: "nissan", src: "/partner/nissan.jpg", alt: "Nissan Logo", width: 80, height: 80 },
  { slug: "peugeot", src: "/partner/peugeot.jpg", alt: "Peugeot Logo", width: 80, height: 80 },
];

const animation = { duration: 10000, easing: (t) => t };
const move = ref(true);

const [container, slider] = useKeenSlider({
  loop: true,
  renderMode: "performance",
  drag: false,
  slides: { perView: "auto", spacing: 40 },
  created(s) {
    s.container.classList.remove("loading");
    startAnimation();
  },
  updated() {
    startAnimation();
  },
  animationEnded() {
    startAnimation();
  },
});

const startAnimation = () => {
  if (move.value && slider.value) {
    slider.value.moveToIdx(slider.value.track.details.abs + 5, true, animation);
  }
};

onMounted(() => {
  startAnimation();
});
</script>
